<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col cols="4">
            <b-form-input

              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search Category..."
            /></b-col>

          <b-col cols="4">     <b-form-input

            v-model="searchQueryCourseName"
            class="d-inline-block mr-1 "
            placeholder="Search Course..."
          /></b-col>

          <b-col cols="4">     <b-form-input

            v-model="searchQueryCity"
            class="d-inline-block mr-1 "
            placeholder="Search City..."
          /></b-col>
          <b-col
            md="6"
            class="mt-2"
            xl="6"
          >
            <b-row>
              <b-col
                md="8"
                xl="8"
              >
                <flat-pickr
                  v-model="date"
                  placeholder="Search by date"
                  class="form-control"
                />
              </b-col>
              <b-col
                md="1"
                xl="1"
              >
                <b-button
                  variant="primary"
                  @click="date = ''"
                >
                  clear
                </b-button>
              </b-col>

              <b-col
                cols="6"
                class="mt-50 d-flex align-items-start justify-content-start"
              >

                <b-button

                  variant="primary"
                  class="mr-1"
                  @click="fetchAllData"
                >
                  Export To Excel
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-table

        ref="refCourseListTable"
        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="accreditationsList"
        :sort-by.sync="sort"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Status -->

        <!-- Column: Actions -->

        <!-- Column: Actions -->

        <template #cell(actions)="data">
          <a
            v-if="$can('delete', 'accreditation')"
            role="button"
            @click="DeleteItem(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <b-table
        id="sampletable"
        style="display: none;"

        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="accreditationsListAll"

        show-empty
        empty-text="No matching records found"
      >

     
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalaccreditations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import { useRouter } from '@core/utils/utils'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import 'flatpickr/dist/flatpickr.css'
import * as XLSX from 'xlsx/xlsx.mjs'

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    flatPickr,
    BTable,
    BPagination,

    vSelect,
  },
  setup() {
    const accreditationsList = ref([])

    const accreditationsListAll = ref([])
    const perPageOptions = [100, 200, 400, 600]
    const perPage = ref(10)
    const totalaccreditations = ref(0)
    const currentPage = ref(1)
    const refCourseListTable = ref('')
    const sort = ref('')
    const SortArray = []
    const searchQuery = ref('')
    const date = ref('')
    const coursesList = ref([])
    const categoriesList = ref([])

    const citiesList = ref([])

    const searchQueryCourseName = ref('')
    const searchQueryCity = ref('')

    store.dispatch('cities/cititesList').then(response => {
      citiesList.value = response.data
    })
    const getCoursesByCategory = id => {
      store
        .dispatch('courses/getAllCoursesList', {
          'filter[category_id]': id,
          'filter[online]': 1,
        })
        .then(response => {
          coursesList.value = response.data
        })
    }

    store.dispatch('categories/CategoryList').then(response => {
      categoriesList.value = response.data
    })
    const tableColumns = [
      { key: 'id', label: 'Id', sortable: true },
      { label: ' category', key: 'category', sortable: true },
      { label: ' course', key: 'course', sortable: true },
      { key: 'city', label: 'city', sortable: true },
      { key: 'type', label: 'type', sortable: true },
      { key: 'date', label: 'date', sortable: true },
      { key: 'actions' },
    ]

    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
            perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalaccreditations.value,
      }
    })
    const meta = reactive({})

    const { route } = useRouter()
    const { id } = route.value.params
    const getaccreditations = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }
      const queryParams = {
        // "filter[search]": searchQuery.value,
        'filter[category]': searchQuery.value,
        'filter[course_name]': searchQueryCourseName.value,
        'filter[city]': searchQueryCity.value,
        'filter[date]': date.value,

        sort: data,
        per_page: perPage.value,
        page: currentPage.value,
      }
      store
        .dispatch('setting/allNgosScheduleCourse', { queryParams, id })
        .then(response => {
          
          accreditationsList.value = response.data.data
          totalaccreditations.value = response.data?.meta.total
        })
    }

    watch([currentPage, perPage, date, sort, searchQuery, searchQueryCity,
      searchQueryCourseName], () => {
      getaccreditations()
    })
    getaccreditations()
    const DeleteItem = schduleId => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store
            .dispatch('setting/DeleteNgosScheduleCorse', { id, schduleId })
            .then(response => {
              getaccreditations()
              Vue.swal({
                title: 'Ngos Deleted ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const save = () => {
     const wb = XLSX.utils.table_to_book(document.getElementById('sampletable'), {dateNF:'mm-dd-yyyy',cellDates:true, raw: true})
      XLSX.writeFile(wb, 'scheduleList.xlsx')
    }
    const fetchAllData = async () => {
      Vue.swal({
        icon: 'warning',
        title: 'Please wait until all the data is fetched.',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      const queryParams = {
        // "filter[search]": searchQuery.value,
        'filter[category]': searchQuery.value,
        'filter[course_name]': searchQueryCourseName.value,
        'filter[city]': searchQueryCity.value,
        'filter[date]': date.value,

        page: 1,
      }
      await store
        .dispatch('setting/allNgosScheduleCourse', { queryParams, id })
        .then(response => {
          accreditationsListAll.value = response.data
        }).then(() => {
          if (accreditationsListAll.value.length > 0) {
            save()
          }
        })
    }
    return {
      save,
      fetchAllData,
      searchQuery,
      date,
      tableColumns,
      accreditationsList,
      accreditationsListAll,
      DeleteItem,
      meta,
      getaccreditations,
      perPageOptions,
      totalaccreditations,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      SortArray,
      sort,
      id,
      searchQueryCity,
      searchQueryCourseName,
      citiesList,
      coursesList,
      categoriesList,
      getCoursesByCategory,
    }
  },
}
</script>

  <style>
  .per-page-selector {
    width: 90px;
  }

  .dropdown-menu.show {
      height: 100px !important;
      overflow: auto !important;
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
